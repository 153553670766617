import * as React from 'react';
import { Layout } from '../../components/layout';
import { Seo } from '../../components/seo';
import * as Styles from '../../styles/pages/_login.module.scss';
import * as CommonStyles from '../../styles/global/_common.module.scss';
import { BackGround } from '../../components/background';
import {Link, useIntl, navigate} from "gatsby-plugin-react-intl"
import queryString from "query-string";
import {postAPI} from "../../services/fetch";
import LoadingElement from "../../components/LoadingElement";
import * as LoadingElementStyles from '../../styles/components/_loading_element.module.scss';

const NameRegistrationPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const [isLoading, setIsLoading] = React.useState(false)
  const [name, setName] = React.useState('')
  const [error, setError] = React.useState({ isError: false, message: '' });

  const onClickNameRegistration = async () => {
    if (name === '') {
      setError({ isError: true, message: t('users.nameRegistration.error.inputUserName') })
      return
    }
    setIsLoading(true)
    setError({isError: false, message: ''})
    const { p } = queryString.parse(location.search)
    if (p) {
      await postAPI({ url: '/api/verify/nickname', params: { p, name }})
        .then((response) => {
          // console.log('#### name register', response)
          if (response.data.result === 0) {
            navigate('/')
          } else if (response.data.result === 1) {
            setError({ isError: true, message: t('users.nameRegistration.error.alreadyRegisterName') })
          } else {
            setError({ isError: true, message: t('users.nameRegistration.error.registrationError') })
          }
        })
        .catch((error) => {
          setError({ isError: true, message: t('users.nameRegistration.error.registrationError') })
          throw error
        });
    } else {
      await postAPI({ url: '/api/user/profile/edit', params: { name }})
        .then((response) => {
          // console.log('#### name register', response)
          if (response.data.result === 0) {
            navigate('/')
          } else if (response.data.result === 1) {
            setError({ isError: true, message: t('users.nameRegistration.error.alreadyRegisterName') })
          } else {
            setError({ isError: true, message: t('users.nameRegistration.error.registrationError') })
          }
        })
        .catch((error) => {
          setError({ isError: true, message: t('users.nameRegistration.error.registrationError') })
          throw error
        });
    }
    setIsLoading(false)
  }

  return (
    <Layout location={location}>
      <div className={isLoading ? '' : LoadingElementStyles.hidden}>
        <LoadingElement />
      </div>
      <Seo title={t('users.nameRegistration.seo.title')} description={t('users.nameRegistration.seo.description')} />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div className={Styles.users}>
              <h1>{t('users.nameRegistration.title')}</h1>
              <section>
                <div>{t('users.nameRegistration.pleaseRegistrationUserName')}<br/><br/></div>
                <div>
                  <div>
                    <label htmlFor="user_name">{t('users.nameRegistration.userName')}</label>
                    <input
                      id="user_name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus="autofocus"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                  <div className={`${Styles.error_message} ${(error.isError) && Styles.is_error}`}>{error.message}</div>
                  <div className={Styles.text_center}>
                    <button
                      type="button"
                      className={Styles.btn_primary}
                      onClick={onClickNameRegistration}
                    >
                      {t('users.nameRegistration.registrationUserName')}
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default NameRegistrationPage;
